import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store"
import HomeView from '../views/HomeView.vue'
import squareDetails from '../views/squareDetails.vue'
import newDetails from '../views/newDetails.vue'
import forumDetails from '../views/forumDetails.vue'
import videoDetails from '../views/videoDetails.vue'
import liveDetails from '../views/liveDetails.vue'
import layout from '../views/layout.vue'
import Login from '../views/login.vue'
import Perfect from '../views/perfect.vue'

const routes = [
  { path: '/', name: 'login', meta: { title: '登录' }, component: Login },
  { path: '/perfect', name: 'perfect', meta: { title: '完善信息' }, component: Perfect },
  {
    path: '/index',
    name: 'index',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: { title: '首页', keepAlive: true },
        component: HomeView
      }
    ]
  },
  {
    path: '/home/squareDetails',
    name: 'squareDetails',
    meta: { title: '广场详情', keepAlive: false },
    component: squareDetails
  },
  {
    path: '/home/newDetails',
    name: 'newDetails',
    meta: { title: '新闻详情', keepAlive: false },
    component: newDetails
  },
  {
    path: '/home/forumDetails',
    name: 'forumDetails',
    meta: { title: '论坛详情', keepAlive: false },
    component: forumDetails
  },
  {
    path: '/home/videoDetails',
    name: 'videoDetails',
    meta: { title: '详情', keepAlive: false },
    component: videoDetails
  },
  {
    path: '/home/liveDetails',
    name: 'liveDetails',
    meta: { title: '详情', keepAlive: false },
    component: liveDetails
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // 返回网页顶部
  window.scrollTo(0, 0);
  // 设置网站title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.name === 'perfect') {
    // if (!store.state.userInfo) {
    //   next('/')
    //   return
    // }
    next()
    return
  }
  // 判断登录
  if (to.name !== 'login') {
    const userInfo = window.localStorage.getItem('UserInfo')
    if (!userInfo) {
      next('/')
      return
    }
  }
  next()
})

export default router
