<template>
  <div class="chatIcons">
    <!-- 刷新 -->
    <div class="chatIcon_head">
      <div class="chatIcon_head_left">
        <img src="@/assets/jian@2x.png" alt=""/>
        <span>{{ props.title }}</span>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="chatIcons_sou">
      <div class="chatIcons_sou_item">
        <input type="text" v-model="from.keyword" placeholder="请输入"/>
        <el-button type="primary" :icon="Search" size="small">搜索</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <div class="chatIcons_list">

    </div>
  </div>
</template>

<script setup>
import {defineProps, defineExpose, ref, reactive} from 'vue'
import {Search} from '@element-plus/icons-vue'
import {plaza_post_index} from '@/api'

const props = defineProps({
  title: String,
  channel: Number,
  create_time: String
})

let list = ref([])  // 列表数据
let loading = ref(true)    // 加载状态
let noMore = ref(false)     // 是否加载完所有数据

let from = reactive({
  page: 1,
  keyword: '',
  time: props.create_time
})

const getDate = (type) => {
  if (type === 'rem') {
    list.value = []
    from.page = 1
    from.keyword = ''
    from.time = ''
  }
  loading.value = true
  plaza_post_index({
    category_id: props.channel,
    page: from.page,
    limit: 20,
    time: from.time,
    keyword: from.keyword,
    category_type: 'investment'
  }).then(res => {
    if (res.code === 1) {
      console.log(res)
    }
  })
      .finally(() => {
        loading.value = false
      })
}

defineExpose({getDate})
</script>

<style lang="scss" scoped>
.chatIcons {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  padding: 18px;
  box-sizing: border-box;

  .chatIcon_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chatIcon_head_left {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }

    .chatIcon_head_right {
      display: flex;
      align-items: center;

      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;

            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }

      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .chatIcons_sou {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;

    .chatIcons_sou_item {
      display: flex;
      align-items: center;

      input {
        width: 200px;
        height: 25px;
        border: none;
        font-size: 13px;
        color: #FFFFFF;
        padding: 0 5px;
        outline: none;
        box-sizing: border-box;
        background: rgba(50, 78, 125, 0.3);
        margin-right: 10px;

        &::-webkit-input-placeholder {
          color: #ffffff7c;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
