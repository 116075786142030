<template>
    <div class="container">
        <div class="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <span class="text">加载中，请等待...</span>
    </div>
</template>

<style scoped>
.container {
    /* width: 25px; */
    /* height: 25px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.text {
    font-size: 13px;
}
.container .loading {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 10px;
    background: rgba(0, 0, 0, 0);
}

/* 6个正方形 */
.loading span {
    height: 100%;
    width: 100%;
    position: absolute;
    animation: move 3.5s linear infinite;
}

@keyframes move {
    74% {
        transform: rotate(600deg);
    }

    79% {
        transform: rotate(720deg);
        opacity: 1;
    }

    80% {
        transform: rotate(720deg);
        opacity: 0;
    }

    100% {
        transform: rotate(810deg);
        opacity: 0;
    }
}

.loading span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading span:nth-child(4) {
    animation-delay: 0.3s;
}



.loading span::before {
    content: '';
    position: absolute;
    height: 4px;
    width: 4px;
    background-color: #fff;
    border-radius: 50%;
    bottom: 0px;
    left: calc(50% - 5px);
}
</style>