<template>
  <div class="chatIcon">
    <!-- 刷新 -->
    <div class="chatIcon_head">
      <div class="chatIcon_head_left">
        <img src="@/assets/jian@2x.png" alt=""/>
        <span>{{ props.title }}</span>
      </div>
      <div class="chatIcon_head_right">
        <span><el-countdown :value="props.timeVal" @finish="finish"/>后自动更新</span>
        <div class="chatIcon_head_right_btn" @click="getData">
          <img src="@/assets/update@2x.png" alt=""/>
          <span>手动更新</span>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="chatIcon_list">
      <div class="chatIcon_list_item" v-for="(item, index) in list" :key="index">
        <div class="chatIcon_list_item_info">
          <div class="chatIcon_list_item_info_head">
            <img src="@/assets/t@2x.png" alt=""/>
            <span>{{ item.name }}</span>
          </div>
          <div class="chatIcon_list_item_info_nr">
            {{ item.title }}
          </div>
        </div>
        <div class="chatIcon_list_item_chart">
          <lineElement :id="'list' + index" :list="item.data"/>
        </div>
      </div>
    </div>
    <Loading v-if="loading"/>
    <notFind v-if="!loading && list.length === 0"/>
    <div class="info" v-if="!loading && list.length > 0 && noMore">
      <el-text class="mx-1" type="info">没有更多了~</el-text>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref, defineEmits, defineExpose} from 'vue'
import {getList} from '@/api/index'
import notFind from '@/components/notFind.vue'
import Loading from '@/components/Loading.vue'
import lineElement from '@/components/lineList.vue'

const props = defineProps({
  title: String,
  timeVal: Number,   // 刷新时间
  channel: Number,   // 子级栏目id
})

const emit = defineEmits(['finish'])

const value = ref([])

let list = ref([])  // 列表数据
let loading = ref(true)    // 加载状态
let noMore = ref(false)     // 是否加载完所有数据

// 倒计时结束
const finish = () => {
  getData()
  emit('finish')
}

// 请求数据
const getData = () => {
  list.value = []
  loading.value = true
  getList({
    startTime: value.value ? value[0] : null,
    endTime: value.value ? value[1] : null,
    category_id: props.channel,
    category_type: 'chart'
  }).then(res => {
    if (res.code === 1) {
      list.value = res.data.chartList
    }
  }).finally(() => {
    loading.value = false
  })
}

defineExpose({getData})
</script>

<style lang="scss" scoped>
.chatIcon {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  padding: 18px;
  box-sizing: border-box;

  .info {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .chatIcon_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chatIcon_head_left {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }

    .chatIcon_head_right {
      display: flex;
      align-items: center;

      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;

            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }

      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .chatIcon_operate {
    width: 100%;
    margin: 17px 0;
    display: flex;
    align-items: center;

    .chatIcon_operate_date {
      width: 200px;
    }
  }

  .chatIcon_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .chatIcon_list_item {
      width: 100%;
      // height: 209px;
      margin-bottom: 19px;
      display: flex;
      flex-direction: column;
      padding: 18px 0 30px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(55, 73, 105, 1);

      &:last-child {
        margin-bottom: 0 !important;
        border: none !important;
      }

      .chatIcon_list_item_chart {
        width: 100%;
        height: 200px;
        margin-top: 10px;
      }

      .chatIcon_list_item_info {
        flex: 1;
        display: flex;
        flex-direction: column;

        .chatIcon_list_item_info_head {
          display: flex;

          img {
            width: 15px;
            height: 9px;
            margin-right: 6px;
            margin-top: 3px;
          }

          span {
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #0CFFFC;
          }
        }

        .chatIcon_list_item_info_nr {
          width: 100%;
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
