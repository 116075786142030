<template>
  <div>
    <img src="@/assets/image.png" alt="图片上传" @click="open(1)"/>
    <!-- 上传图片 -->
    <input type="file" style="display: none;" accept=".jpg,.png,.JPG,.JPEG,.PNG" ref="Img" @change="uploads"/>
  </div>
</template>

<script setup>
import {ref, reactive, defineProps, defineEmits, computed} from 'vue'
import {upload} from '@/api'


let props = defineProps({
  content: String,
  obj: Object,
  type: String
})
let from = reactive({
  message: '',
  fileImgs: [],
  fileVideos: [],
  file: []
})
let emit = defineEmits(['result'])
let typeFile = ref(1)
const Img = ref(null)   // 上传图片

const open = (type) => {
  typeFile.value = type
  if (type == 1) {
    Img.value.click()
  }
}

const uploads = (event) => {
  const formdate = new FormData()
  formdate.append('file', event.target.files[0])
  uploadFile(formdate)
}

const uploadFile = async (formdate) => {
  const res = await upload(formdate)
  if (res.code === 1) {
    if (typeFile.value === 1) {
      from.fileImgs.push(res.data)
      handleUploadSuccess(res.data.url, 'image')
    }
  }
  // 清空input值
  Img.value.value = ''

}

const handleUploadSuccess = (response, fileType, action = 1) => {
  let content = props.content
  let item = props.obj
  let type = props.type
  if (fileType === 'image') {
    // 图片转换为 Markdown 格式
    const markdownImage = `![Image Description](${response})`;
    if (action) {
      content += '\n' + markdownImage;
    } else {
      content = content.replace(markdownImage, '').trim();
    }
  } else if (fileType === 'mp4') {
    // MP4 文件转换为 Markdown 视频链接格式（这只是一个示例，Markdown 标准本身不支持视频嵌入）
    const markdownVideo = `[Video Link](${response})`;
    if (action) {
      content += '\n' + markdownVideo;
    } else {
      content = content.replace(markdownVideo, '').trim();
    }
  } else if (fileType === 'attachment') {
    // 附件转换为 Markdown 下载链接格式
    const markdownAttachment = `[Download Attachment](${response})`;
    if (action) {
      content += '\n' + markdownAttachment;
    } else {
      content = content.replace(markdownAttachment, '').trim();
    }
  }

  emit('result', {content, item, type})
}
</script>
<style lang="scss" scoped>
img {
  width: 13px;
  height: 13px;
  margin-right: 8px;
  margin-top: 4px;
}
</style>
