export const getCurrentDate = () => {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    return year + "-" + month + "-" + day;
}

// 中国标准时间转为 yyyy-mm-dd
export const timestampToTime = (timestamp, type) => {
    let date = null
    if (!timestamp) {
        date = new Date();
    } else {
        date = new Date(timestamp);
    }
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    let h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    let M = date.getMinutes()
    M = M < 10 ? ('0' + M) : M
    let s = date.getSeconds()
    s = s < 10 ? ('0' + s) : s
    if (type === 1) {
        return y + '-' + m + '-' + d;
    } else {
        return y + '-' + m
    }
}

export const toBase64 = (str) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
}

export const fromBase64 = (base64) => {
    return decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
