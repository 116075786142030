import { createStore } from 'vuex'
import { SET_USERINFO, SET_EMPTY, GET_USERINFO, SET_TOKEN } from './mutation-types'
import { getUserDetails } from '@/api/index'

const USER = window.localStorage.getItem('UserInfo') ? JSON.parse(window.localStorage.getItem('UserInfo')) : null
const TOKEN = window.localStorage.getItem('token')

export default createStore({
  state: {
    userInfo: USER,
    token: TOKEN
  },
  mutations: {
    [SET_TOKEN](state, val) {
      state.token = val
      window.localStorage.setItem('token', val)
    },
    [SET_USERINFO](state, user) {
      state.userInfo = user
      window.localStorage.setItem('UserInfo', JSON.stringify(user))
    },
    [SET_EMPTY](state) {
      state.userInfo = ''
      window.localStorage.clear()
    }
  },
  actions: {
    async [GET_USERINFO](context) {
      let res = await getUserDetails({})
      if (res.code === 1) {
        context.commit('setUserInfo', res.data)
      }
    }
  }
})
