<template>
    <div class="line">
        <div class="line_tu" :id="id"></div>
    </div>
</template>

<script setup>
import { defineProps, onMounted, inject, defineExpose } from 'vue'

const echarts = inject('echarts')

const data = defineProps({
    id: String
})

let chartDom = null
let myChart = null

const setLine = (arr) => {
    if (arr.length === 0) return
    let xAxis = []
    let series = []
    let data = []

    arr.forEach(item => {
        xAxis.push(
            {
                axisLabel: {
                    textStyle: {
                        color: '#ffffff'
                    }
                },
                data: item.name
            }
        )
        series.push(
            {
                name: item.line,
                type: 'line',
                showSymbol: false,
                data: item.val
            }
        )
        data.push(item.line)
    })
    var option = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data,
            textStyle: {
                color: '#fff'
            }
        },
        xAxis,
        grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '20%',
            containLabel: true
        },
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: '#ffffff'
                    }
                }
            }
        ],
        dataZoom: [
            {
                startValue: arr[0].name[0]
            },
            {
                type: 'inside',
                textStyle: {
                    color: '#fff'
                },
                moveHandleStyle: {
                    color: '#fff'
                },
                handleStyle: {
                    color: '#fff'
                }
            }
        ],
        series
    };
    myChart.setOption(option);
}

defineExpose({ setLine })

onMounted(() => {
    chartDom = document.getElementById(data.id);
    myChart = echarts.init(chartDom);
})
</script>

<style lang="scss" scoped>
.line {
    width: 100%;
    height: 100%;
    .line_tu {
        width: 100%;
        height: 100%;
    }
}
</style>
