import request from './request';

const axiosInstance = request.axiosInstance
// 图片验证码
export function getCaptca (params) {
    return axiosInstance.get('api/user/getCaptca', {params})
}

// 登录
export function Login (data) {
    return axiosInstance.post('api/user/login', data)
}

// 修改密码
export function resetpwd (data) {
    return axiosInstance.post('api/user/resetpwd', data)
}

// 注册
export function register (data) {
    return axiosInstance.post('api/user/register', data)
}

// 获取栏目列表
export function getcolumnlist (params) {
    return axiosInstance.get('api/user/getcolumnlist', {params})
}

// 轮播图列表
export function get_management (params = {}) {
    return axiosInstance.get('api/user/get_management', {params})
}

// 栏目详情
export function getcolumninfo (params) {
    return axiosInstance.get('api/user/getcolumninfo', {params})
}

// 用户-我发布的文章
export function newsMy (data) {
    return axiosInstance.post('api/news/my', data)
}

// 新闻-获取新闻列表
export function newsList (params) {
    return axiosInstance.get('api/news/index', {params})
}

// 用户-收藏
export function doFavorite (id) {
    return axiosInstance.post(`api/news/doFavorite/id/${id}`)
}

// 用户-点赞
export function doLike (id) {
    return axiosInstance.post(`api/news/doLike/id/${id}`)
}

// 用户-发布文章
export function newsPost (params) {
    return axiosInstance.get('api/news/post', {params})
}

// 新闻-上传文件
export function upload (data) {
    return axiosInstance.post('api/Common/upload', data, { headers: {'Content-Type': 'multipart/form-data'} })
}

// 用户-发表评论
export function sendComment (params) {
    return axiosInstance.get('api/comment/post.html', {params})
}

// 用户-视频
export function liveList (params) {
    return axiosInstance.get('api/live/list', {params})
}

// 新闻- 获取新闻详情及评论
export function getNewsDetail (id) {
    return axiosInstance.get(`api/news/detail/id/${id}`)
}

// 用户-取消收藏
export function closeDoFavorite (id) {
    return axiosInstance.get(`api/news/cancelFavorite/id/${id}`)
}

// 用户-取消点赞
export function cancelLike (id) {
    return axiosInstance.get(`api/news/cancelLike/id/${id}`)
}

// 获取视频列表
export function videoList (params) {
    return axiosInstance.get(`api/video/list`, { params })
}

// 获取视频列表
export function videoDetail (id) {
    return axiosInstance.get(`api/video/detail/id/${id}`)
}

// 视频点赞
export function videoDoLike (id) {
    return axiosInstance.get(`api/video/doLike/id/${id}`)
}

// 视频取消点赞
export function videoCancelLike (id) {
    return axiosInstance.get(`api/video/cancelLike/id/${id}`)
}

// 视频收藏
export function videoDoFavorite (id) {
    return axiosInstance.get(`api/video/doFavorite/id/${id}`)
}

// 视频取消收藏
export function videoCancelFavorite (id) {
    return axiosInstance.get(`api/video/cancelFavorite/id/${id}`)
}

// 视频发布标记
export function calendarTag (params) {
    return axiosInstance.get('api/video/calendarTag', { params })
}

// 新闻-日历标记
export function newsCalendarTag (params) {
    return axiosInstance.get('api/news/calendarTag', { params })
}

// 新闻-曲线图图表
export function getList (data) {
    return axiosInstance.post('api/v2/plaza_post/index', data)
}

// 新闻-顶部三个图表
export function getTopList (params) {
    return axiosInstance.get('api/v2/chart/getTopList', params)
}

// 手机号验证码登录（未注册则自动注册）
export function mobilelogin (data) {
    return axiosInstance.post('api/user/mobilelogin', data)
}

// 获取短信验证码
export function send (data) {
    return axiosInstance.post('api/sms/send', data)
}

// ------------------ V2 ------------------

// 文章列表
export function plaza_post_index (params) {
    return axiosInstance.get('api/v2/plaza_post/index', {params})
}

// 广场文章列表
export function getCategoryList (params) {
    return axiosInstance.get('api/v2/plaza_post/getCategoryList', {params})
}

// 小道消息
export function grapevine () {
    return axiosInstance.get('api/v2/Grapevine/index')
}

// 小道消息
export function logout (data = {}) {
    return axiosInstance.post('api/user/logout', data)
}

// 文章详情
export function show (params) {
    return axiosInstance.get('api/v2/plaza_post/show', {params})
}

// 添加点赞
export function addLive (data) {
    return axiosInstance.post('api/v2/comment/addLive', data)
}

// 删除点赞
export function delLive (data) {
    return axiosInstance.post('api/v2/comment/delLive', data)
}

// 添加收藏
export function addFavorite (data) {
    return axiosInstance.post('api/v2/comment/addFavorite', data)
}

// 删除收藏
export function delFavorite (data) {
    return axiosInstance.post('api/v2/comment/delFavorite', data)
}

// 添加评论
export function addComment (data) {
    return axiosInstance.post('api/v2/comment/addComment', data)
}

// 获取用户积分列表
export function getScoreList (params) {
    return axiosInstance.get('api/v2/user/getScoreList', {params})
}

// 获取我的帖子列表
export function getMyPlazaPostList (params) {
    return axiosInstance.get('api/v2/user/getMyPlazaPostList', {params})
}

// 获取用户个人信息
export function getUserDetails (params) {
    return axiosInstance.get('api/v2/user/getUserDetails', {params})
}

// 日历标记(plaza:广场帖子,news:新闻,video:视频,plate:论坛)
export function mark (data) {
    return axiosInstance.post('api/v2/plaza_post/calendarTag', data)
}

// 获取我的收藏列表
export function getMyFavorite (params) {
    return axiosInstance.get('api/v2/user/getMyFavorite', {params})
}

// 获取我的评论列表
export function getMyCommentList (params) {
    return axiosInstance.get('api/v2/user/getMyCommentList', {params})
}

// 获取评论列表
export function getComment (params) {
    return axiosInstance.get('api/v2/comment/inedx', {params})
}

// 文章添加
export function plaza_post_add (data) {
    return axiosInstance.post('api/v2/plaza_post/add', data)
}

// 文章添加
export function updateUser (data) {
    return axiosInstance.post('api/v2/user/updateUser', data)
}

// 获取站点初始化配置
export function systemConfig () {
    return axiosInstance.get('api/v2/config/getWebConfig')
}
