<template>
  <div class="login">
    <div class="login_bos_title">
      完善个人信息
    </div>

    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="top" style="width: 65%">
      <el-row :gutter="40">
        <el-col :span="6">
          <div class="custom-label">
            <el-form-item label="上传头像" prop="avatar_file" style="color: #fff;">
              <el-upload :show-file-list="false" class="avatar-uploader"
                         :action="base_url + 'api/common/upload'"
                         :on-success="handleAvatarSuccess"
                         :before-upload="beforeAvatarUpload" :headers="{token:token}">
                <img v-if="ruleForm.avatar_file" :src="ruleForm.avatar_file" class="avatar"/>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus/>
                </el-icon>
              </el-upload>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="10">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="姓名/昵称" prop="nickname">
                <el-input v-model="ruleForm.nickname"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="邀请码" prop="invitation_code">
                <el-input v-model="ruleForm.invitation_code"/>
              </el-form-item>
            </el-col>

          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="6">
          <el-form-item label="上传名片" prop="user_image_file">
            <el-upload :show-file-list="false" class="avatar-uploader"
                       :action="base_url + 'api/common/upload'"
                       :on-success="handleUserImageFileSuccess"
                       :before-upload="beforeUserImageFileUpload" :headers="{token:token}">
              <img v-if="ruleForm.user_image_file" :src="ruleForm.user_image_file" class="avatar"/>
              <el-icon v-else class="avatar-uploader-icon">
                <Plus/>
              </el-icon>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="职位" prop="position">
                <el-input v-model="ruleForm.position"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="单位" prop="employer">
                <el-input v-model="ruleForm.employer"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="12">
          <el-button style="width: 100%;" type="primary" @click="submitForm(ruleFormRef)">
            提交
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button style="width: 100%;" type="primary" @click="gohome">
            取消
          </el-button>
        </el-col>
      </el-row>

    </el-form>
  </div>
</template>

<script setup>
import {reactive, ref} from 'vue'
import {useStore} from 'vuex'
import request from '@/api/request'
import {Plus} from '@element-plus/icons-vue'
import {updateUser} from "@/api";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router";

const router = useRouter()
const store = useStore()
const base_url = request.BASEURL
const ruleFormRef = ref(null)
const token = store.state.token
const ruleForm = reactive({
  nickname: '',
  avatar_file: '',
  invitation_code: '',
  employer: '',
  position: '',
  user_image_file: ''
})

const rules = reactive({
  nickname: [
    {required: true, message: 'Please input Activity name', trigger: 'blur'}
  ]
})
const gohome = () => {
  router.push({path: '/index'})
}
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      updateUser(ruleForm).then(res => {
        if (res.code === 1) {
          ElMessage.success({message: '更新成功'})
          router.push({path: '/index'})
        }
      })
    }
  })
}

const handleAvatarSuccess = (uploadFile, uploadFiles) => {
  if (uploadFile.code === 1) {
    ruleForm.avatar_file = uploadFile.data.url
  }
}

const beforeAvatarUpload = (file) => {
  console.log(file)
}

const handleUserImageFileSuccess = (uploadFile, uploadFiles) => {
  if (uploadFile.code === 1) {
    ruleForm.user_image_file = uploadFile.data.url
  }
}

const beforeUserImageFileUpload = (file) => {
  console.log(file)
}
</script>

<style lang="scss">
.el-form-item__label {
  color: #FFFFFF !important;
  /* 其他样式属性... */
}

.avatar-uploader {
  width: 178px;
  height: 178px;
  display: block;
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
  border-color: var(--el-color-primary);
}
.avatar{
  width: 100%;
  object-fit: contain;
  display: block;
}
.login {
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;

  .login_bos_title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 40px;
  }
}
</style>
