<template>
  <div class="textarea" @dragenter="handleDragEnter" @dragover="handleDragEnter" @drop="handleDrop">
    <textarea cols="30" rows="10" v-model="from.message" placeholder="分享我的内容"></textarea>
    <div class="textarea_bottom">
      <div class="textarea_bottom_left">
        <el-popover
            placement="bottom"
            title="请选择表情"
            :width="300"
            trigger="click">
          <template #reference>
            <div class="textarea_bottom_left_item">
              <img src="@/assets/icon.png" alt=""/>
              <span>表情</span>
            </div>
          </template>
          <chatIcon @changeText="getIcon"/>
        </el-popover>
        <div class="textarea_bottom_left_item" @click="open(1)">
          <img src="@/assets/image.png" alt=""/>
          <span>图片</span>
        </div>
        <div class="textarea_bottom_left_item" @click="open(2)">
          <img src="@/assets/video.png" alt=""/>
          <span>视频</span>
        </div>
        <div class="textarea_bottom_left_item" @click="open(3)">
          <img src="@/assets/file.png" alt=""/>
          <span>文件</span>
        </div>
      </div>
      <div class="textarea_bottom_right" @click="send">发送</div>
    </div>
    <!-- 上传的文件集合 -->
    <div class="textarea_files" v-if="fileList && fileList.length > 0">
      <div class="textarea_files_item" v-for="(item, index) in fileList" :key="index">
        <div class="textarea_files_item_close" @click="deleFile(item)">x</div>
        <img style="height: 70px" :src="item.fullurl" alt="" v-if="!item.isFile"/>
        <span v-else>文件</span>
      </div>
    </div>
    <!-- 上传图片 -->
    <input type="file" style="display: none;" accept=".jpg,.png,.JPG,.JPEG,.PNG" ref="Img" @change="uploads"/>
    <!-- 上传视频 -->
    <input type="file" style="display: none;" accept=".mp4" ref="Video" @change="uploads"/>
    <!-- 上传文件 -->
    <input type="file" style="display: none;" accept=".word,.xlsx,.doc,.docx,.txt,.PPT,.PDF,.WAR" ref="File"
           @change="uploads"/>
  </div>
</template>

<script setup>
import chatIcon from '@/components/chatIcon.vue'
import {upload, plaza_post_add} from '@/api/index'
import {ref, reactive, defineProps, defineEmits, computed} from 'vue'
import {ElMessage} from 'element-plus'
import {marked} from 'marked';

let props = defineProps({
  channel: String | Number,    // 子级栏目id
  typeView: String | Number    // 父级栏目id
})

let emit = defineEmits(['result'])

const Img = ref(null)   // 上传图片
const Video = ref(null)   // 上传视频
const File = ref(null)   // 上传文件

let typeFile = ref(1)

let from = reactive({
  message: '',
  fileImgs: [],
  fileVideos: [],
  file: []
})

// 文件拖拽上传
const handleDrop = (event) => {
  event.preventDefault();

  typeFile.value = 3
  for (let i = 0; i < event.dataTransfer.files.length; i++) {
    if (event.dataTransfer.files[i].name.includes('.word') ||
        event.dataTransfer.files[i].name.includes('.xlsx') ||
        event.dataTransfer.files[i].name.includes('.doc') ||
        event.dataTransfer.files[i].name.includes('.docx') ||
        event.dataTransfer.files[i].name.includes('.txt') ||
        event.dataTransfer.files[i].name.includes('.PPT') ||
        event.dataTransfer.files[i].name.includes('.PDF') ||
        event.dataTransfer.files[i].name.includes('.WAR')) {
      const formdate = new FormData()
      formdate.append('file', event.dataTransfer.files[i])
      uploadFile(formdate)
    } else {
      ElMessage.warning({message: '文件类型不支持上传'})
    }
  }
}
const handleDragEnter = (e) => {
  e.preventDefault(); // 阻止浏览器默认拖拽行为
}

// 实时获取
const fileList = computed(() => {
  let arr = []
  let data = [...from.fileImgs, ...from.fileVideos, ...from.file]
  data.forEach(item => {
    if (/\.(doc|docx|word|xlsx|txt|mp4)$/i.test(item.url)) {
      item.isFile = true
    } else {
      item.isFile = false
    }
    arr.push(item)
  })
  return arr
})

console.log(fileList)

// 删除文件
const deleFile = (obj) => {
  for (let i = 0; i < from.fileImgs.length; i++) {
    if (from.fileImgs[i].url === obj.url) {
      from.fileImgs.splice(i, 1)
      handleUploadSuccess(obj.url, 'image', 0)
      return
    }
  }
  for (let i = 0; i < from.fileVideos.length; i++) {
    if (from.fileVideos[i].url === obj.url) {
      from.fileVideos.splice(i, 1)
      handleUploadSuccess(obj.url, 'mp4', 0)
      return
    }
  }
  for (let i = 0; i < from.file.length; i++) {
    if (from.file[i].url === obj.url) {
      from.file.splice(i, 1)
      handleUploadSuccess(obj.url, 'attachment', 0)
      return
    }
  }
}
// 选择表情
const getIcon = (e) => {
  from.message = from.message += e
}
// 打开选择文件
const open = (type) => {
  typeFile.value = type
  if (type == 1) {
    Img.value.click()
  } else if (type == 2) {
    Video.value.click()
  } else if (type == 3) {
    File.value.click()
  }
}
// 获取文件
const uploads = (event) => {
  const formdate = new FormData()
  formdate.append('file', event.target.files[0])
  uploadFile(formdate)
}
// 上传文件
const uploadFile = async (formdate) => {
  const res = await upload(formdate)
  if (res.code === 1) {
    if (typeFile.value === 1) {
      from.fileImgs.push(res.data)
      handleUploadSuccess(res.data.url, 'image')
    } else if (typeFile.value === 2) {
      from.fileVideos.push(res.data)
      handleUploadSuccess(res.data.url, 'mp4')
    } else if (typeFile.value === 3) {
      from.file.push(res.data)
      handleUploadSuccess(res.data.url, 'attachment')
    }
  }
  // 清空input值
  Img.value.value = ''
  Video.value.value = ''
  File.value.value = ''
}
// 发送新闻
const send = () => {
    console.log(from)
    console.log(props)
    // return
  if (!from.message) {
    ElMessage({message: '内容不能为空', type: 'warning'})
    return
  }
  let category_type = ''
  if (props.typeView == 1) {
    category_type = ''
  } else if (props.typeView == 3 || props.typeView == 19) {
    category_type = 'plate'
  } else if (props.typeView == 5) {
    category_type = 'news'
  }
  const htmlContent = marked(from.message);

  plaza_post_add({
    title: '默认标题',
    content: htmlContent,
    category_id: props.channel == 1 ? 0 : props.channel,
    category_type,
    file: '',
    accessory: '',
    post_keywords: '',
    post_excerpt: '',
    post_source: ''
  }).then(res => {
    if (res.code === 1) {
      ElMessage({message: res.msg, type: 'success'})
      from.message = ''
      from.fileImgs = []
      from.fileVideos = []
      from.file = []
      emit('result', true)
    } else {
      emit('result', false)
    }
  })
}

const handleUploadSuccess = (response, fileType, action = 1) => {
  if (fileType === 'image') {
    // 图片转换为 Markdown 格式
    const markdownImage = `![Image Description](${response})`;
    if (action) {
      from.message += '\n' + markdownImage;
    } else {
      from.message = from.message.replace(markdownImage, '').trim();
    }
  } else if (fileType === 'mp4') {
    // MP4 文件转换为 Markdown 视频链接格式（这只是一个示例，Markdown 标准本身不支持视频嵌入）
    const markdownVideo = `[Video Link](${response})`;
    if (action) {
      from.message += '\n' + markdownVideo;
    } else {
      from.message = from.message.replace(markdownVideo, '').trim();
    }
  } else if (fileType === 'attachment') {
    // 附件转换为 Markdown 下载链接格式
    const markdownAttachment = `[Download Attachment](${response})`;
    if (action) {
      from.message += '\n' + markdownAttachment;
    } else {
      from.message = from.message.replace(markdownAttachment, '').trim();
    }

  }
}
</script>

<style lang="scss" scoped>
.textarea {
  width: 100%;
  // height: 105px;
  padding: 12px;
  background: #FFFFFF;
  border: 1px solid #1784FC;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  textarea::-webkit-scrollbar {
    width: 5px;
  }

  textarea::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #5D6B82;
  }

  textarea {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    resize: none;
    font-size: 13px;
    color: #000000;
  }

  .textarea_files {
    width: 100%;
    display: flex;
    align-items: center;

    .textarea_files_item {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:last-child {
        margin: 0 !important;
      }

      span {
        color: #666666;
        font-size: 13px;
        font-weight: 400;
      }

      .textarea_files_item_close {
        position: absolute;
        top: -2px;
        right: -5px;
        width: 15px;
        height: 15px;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
      }

      img {
        width: 100%;
      }
    }
  }

  .textarea_bottom {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #D8D8D8;

    .textarea_bottom_left {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;

      .textarea_bottom_left_item {
        display: flex;
        align-items: center;
        margin-right: 17px;
        cursor: pointer;

        &:last-child {
          margin-right: 0 !important;
        }

        img {
          width: 14px;
          // height: 14px;
          margin-right: 5px;
        }

        span {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #044E94;
        }
      }
    }

    .textarea_bottom_right {
      flex-shrink: 0;
      height: 20px;
      width: 42px;
      line-height: 20px;
      text-align: center;
      background: linear-gradient(0deg, #085EAF, #2791F4);
      border-radius: 10px;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
</style>
