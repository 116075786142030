<template>
    <div class="chatIcon">
        <!-- 刷新 -->
        <div class="chatIcon_head">
            <div class="chatIcon_head_left">
                <img src="@/assets/jian@2x.png" alt="" />
                <span>{{ props.title }}</span>
            </div>
            <div class="chatIcon_head_right">
                <span><el-countdown :value="props.timeVal" @finish="finish" />后自动更新</span>
                <div class="chatIcon_head_right_btn" @click="getVideoList('rem')">
                    <img src="@/assets/update@2x.png" alt="" />
                    <span>手动更新</span>
                </div>
            </div>
        </div>
        <!-- 直播预告 -->
        <div class="chatIcon_live">
            <img class="image" src="@/assets/bg.png" alt="" />
            <img class="play" src="@/assets/bf.png" alt="" />
            <div class="chatIcon_live_text">
                <span>直播预告/直播详情直播预告/直播详情直播预告/直播详情直播预告/直播详情直播预告</span>
            </div>
        </div>
        <!-- 视频列表   v-loading="from.loading" element-loading-background="rgba(0, 0, 0, 0.8)" -->
        <div class="chatIcon_list">
            <div class="chatIcon_list_left">
                <div class="chatIcon_list_left_item" v-for="(item, index) in from.listLeft" :key="index" @click="jump(item.id)">
                    <div class="chatIcon_list_left_item_img">
                        <img :src="item.thumbnail" alt="" />
                    </div>
                    <div class="chatIcon_list_left_item_wz">
                        {{ item.title }}
                    </div>
                </div>
            </div>
            <div class="chatIcon_list_right">
                <div class="chatIcon_list_right_item" v-for="(item, index) in from.listRight" :key="index" @click="jump(item.id)">
                    <div class="chatIcon_list_left_item_img">
                        <img :src="item.thumbnail" alt="" />
                    </div>
                    <div class="chatIcon_list_right_item_wz">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
        <Loading v-if="from.loading" />
        <notFind v-if="!from.loading && from.listLeft.length === 0" />
        <div class="info" v-if="!from.loading && from.listLeft.length > 0 && noMore">
            <el-text class="mx-1" type="info">没有更多了~</el-text>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineExpose, defineEmits, reactive, ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { videoList, plaza_post_index } from '@/api'
import Loading from '@/components/Loading.vue'
import { timestampToTime } from '@/utils/utils.js'
import notFind from '@/components/notFind.vue'

const props = defineProps({
    title: String,
    channel: Number,     // 栏目id
    timeVal: Number,
    create_time: String
})

const emit = defineEmits(['finish'])
const router = useRouter()
let noMore = ref(false)
let from = reactive({
    listLeft: [],
    listRight: [],
    page: 1,
    title: '',
    create_time: '',
    total: 0,
    loading: true,
    keyword: '',
    sortId: 'desc',
    dateType: ''
})

// 关键字搜索
const getKeyword = (val) => {
    from.title = val
    getVideoList('rem')
}

const getVideoList = (type) => {
    from.loading = true
    if (type === 'rem') {
        from.listLeft = []
        from.listRight = []
        from.page = 1
        noMore.value = false
    }
    if (noMore.value) return
    // videoList({
    //     title: from.title,
    //     per_page: 10,
    //     page: from.page,
    //     create_time: props.create_time ? props.create_time : timestampToTime(null, 1)
    // })
    plaza_post_index({
        category_id: props.channel,
        page: from.page,
        limit: 20,
        type: props.create_time ? '' : from.dateType,
        orderway: from.sortId,
        keyword: from.keyword,
        time: props.create_time ? props.create_time : 0,
        category_type: 'video'
    }).then(res => {
        if (res.code === 1) {
            from.page += 1
            from.listRight = []
            from.listLeft = []
            let isPush = true
            res.data.videoList.forEach(item => {
                if (!isPush) {
                    from.listRight.push(item)
                    isPush = true
                } else {
                    from.listLeft.push(item)
                    isPush = false
                }
            })
            from.total = res.data.total
            let num = from.listRight.length + from.listLeft.length
            if ((num === res.data.total) || res.data.videoList.length === 0) {
                noMore.value = true
            }
        }
    }).finally(() => {
        from.loading = false
    })
}

const jump = (id) => {
    const routeUrl = router.resolve({
        path: "/home/videoDetails",
        query: { id }
    });
    window.open(routeUrl.href, "_blank");
}

const finish = () => {
    getVideoList('rem')
    emit('finish')
}

const scrollFn = (e) => {
    //可视区域
    let clientHeight = document.documentElement.clientHeight
    // 滚动文档高度
    let scrollHeight = document.body.scrollHeight
    // 已滚动的高度
    let scrollTop = parseInt(document.documentElement.scrollTop)
    if ((scrollTop + clientHeight) === scrollHeight) {
        console.log('触底了')
        if (!noMore.value) {
            console.log('加载了')
            getVideoList()
        }
    }
}

defineExpose({ getVideoList, getKeyword })

onMounted(() => {
    window.addEventListener("scroll", scrollFn)
})

onUnmounted(() => {
    window.removeEventListener("scroll", scrollFn);
})
</script>

<style lang="scss" scoped>
    .chatIcon {
        width: 100%;
        background: #02183F;
        border-radius: 16px;
        padding: 18px;
        box-sizing: border-box;
        .info {
            width: 100%;
            text-align: center;
            margin-top: 20px;
        }
        .chatIcon_head {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .chatIcon_head_left {
                display: flex;
                align-items: center;
                img {
                    width: 16px;
                    height: 10px;
                    margin-right: 6px;
                }
                span {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00D5D8;
                }
            }
            .chatIcon_head_right {
                display: flex;
                align-items: center;
                span::v-deep {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    .el-statistic {
                        .el-statistic__content {
                            display: flex;
                            align-items: center;
                            .el-statistic__number {
                                font-size: 13px;
                                font-family: Source Han Sans CN;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
                .chatIcon_head_right_btn {
                    // width: 78px;
                    height: 26px;
                    padding: 8px;
                    box-sizing: border-box;
                    background: linear-gradient(0deg, #085EAF, #2791F4);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-left: 16px;
                    img {
                        width: 11px;
                        height: 11px;
                        margin-right: 6px;
                    }
                    span {
                        font-size: 11px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
            }
        }
        .chatIcon_live {
            width: 100%;
            height: 213px;
            border-radius: 6px;
            margin-top: 13px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-bottom: 21px;
            .image {
                width: 100%;
            }
            .play {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 31px;
                height: 31px;
                cursor: pointer;
                transform: translate(-50%, -50%);
            }
            .chatIcon_live_text {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 0 23px 12px 23px;
                box-sizing: border-box;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                span {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
        .chatIcon_list {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .chatIcon_list_left {
                width: 242px;
                height: auto;
                .chatIcon_list_left_item {
                    width: 100%;
                    height: 256px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 24px;
                    position: relative;
                    cursor: pointer;
                    &:nth-child(even) {
                        height: 355px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .chatIcon_list_left_item_img {
                        width: 100%;
                        height: 100%;
                        img {
                            height: 100%;
                        }
                    }
                    .chatIcon_list_left_item_wz {
                        width: 100%;
                        position: absolute;
                        bottom: 14px;
                        left: 0;
                        padding: 0 15px;
                        box-sizing: border-box;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow: ellipsis;
                        font-size: 13px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
            .chatIcon_list_right {
                width: 242px;
                height: auto;
                .chatIcon_list_right_item {
                    width: 100%;
                    height: 355px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 24px;
                    position: relative;
                    cursor: pointer;
                    &:nth-child(even) {
                        height: 256px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    img {
                        height: 100%;
                    }
                    .chatIcon_list_right_item_wz {
                        width: 100%;
                        position: absolute;
                        bottom: 14px;
                        left: 0;
                        padding: 0 15px;
                        box-sizing: border-box;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow: ellipsis;
                        font-size: 13px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
</style>